@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: Poppins, sans-serif;
}

.hero {
    background: url(components/hero.jpg) no-repeat center center/cover;
}

.hero,
.hero-filter {
    height: 48em;
}

.hero-filter {
    /* background opacity */
    background: linear-gradient(45deg, rgba(237, 70, 144, 0.8), rgba(85, 34, 204, 0.9));
    /* opacity: 0.85; */

}